import * as React from 'react';
import { useState } from "react";

import { Box, Stack, Dialog, IconButton, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";

import DrawerLogo from '../resources/DrawerLogoBlack.svg';
import logo from "../resources/logo/CrossChainLabsLogo.svg";
import x from '../resources/x.svg';

import { Link, useNavigate } from 'react-router-dom';


const HeaderMobile = ({ value, setValue }) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            // behavior: 'smooth'
        });
    };

    const goHomePage = () => {
        setValue(0);
        navigate('/');
        scrollToTop();
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
            }}
        >
            <Stack direction='column' sx={{ justifyContent: 'center', alignItems: 'center', marginTop: '2rem', marginLeft: '28%' }}>
                <img
                    alt="logo"
                    src={logo}
                    style={{
                        width: '2rem',
                        cursor: 'pointer',
                        marginBottom: '1rem',
                    }}
                    onClick={goHomePage}
                />
                <Typography
                    variant="h6"
                    component="div"
                    sx={{
                        flexGrow: 1,
                        fontSize: '24px'
                    }}
                    color="#000000"
                >
                    CrossChain Labs
                </Typography>
            </Stack>
            <IconButton onClick={handleClickOpen} sx={{ marginLeft: 'auto', marginRight: '1rem' }}>
                <img src={DrawerLogo} alt='drawerOpen' />
            </IconButton>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <IconButton onClick={handleClose} sx={{ marginLeft: 'auto', marginRight: '1rem', marginTop: '2rem', marginBottom: '1rem' }}>
                    <img src={x} alt='close' style={{ width: '1.5rem', height: '1.5rem' }} />
                </IconButton>
                <ListItem disableGutters >
                    <ListItemButton
                        component={Link} to="/"
                        onClick={() => { scrollToTop(); handleClose(); setValue(0); }}
                    >
                        <ListItemText
                            primary='About us'
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                            primaryTypographyProps={{ fontSize: '28px' }}
                        />
                    </ListItemButton>
                </ListItem>
                <ListItem disableGutters>
                    <ListItemButton
                        component={Link} to="/meet-the-team"
                        onClick={() => { scrollToTop(); handleClose(); setValue(1); }}
                    >
                        <ListItemText
                            primary='Meet the team'
                            sx={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                            primaryTypographyProps={{ fontSize: '28px' }}
                        />
                    </ListItemButton>
                </ListItem>
                <ListItem disableGutters>
                    <ListItemButton
                        component={Link} to="/get-in-touch"
                        onClick={() => { scrollToTop(); handleClose(); setValue(2); }}
                    >
                        <ListItemText
                            primary='Get in touch'
                            sx={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                            primaryTypographyProps={{ fontSize: '28px', color: '#6ca4a4', }}
                        />
                    </ListItemButton>
                </ListItem>
            </Dialog>

        </Box>
    )
}
export default HeaderMobile