import * as React from 'react';
import {
    Box,
    Typography
} from '@mui/material';




export default function Header({ value, setValue }) {


    return (
        <Box sx={{ flexGrow: 1, marginBottom: '2rem' }}>
            <Typography
                variant='subtitle1'
                align='center'
                sx={{
                    opacity: 0.8
                }}
            >
                ©2020 proudly created by CrossChain Labs.
            </Typography>
        </Box>
    );
}