import { Tabs } from "@mui/material";

import { styled } from '@mui/material/styles';

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        // maxWidth: ,
        width: '75%',
        backgroundColor: '#000',
    },
    marginRight: '2.5rem'
});

export default StyledTabs;