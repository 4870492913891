import React from 'react';

import {
    Avatar,
    Typography,
    Stack,
    Link
} from '@mui/material';

import pozaCriss from '../resources/Cristina.jpeg';
import pozaCata from '../resources/Cata.jpeg';
import pozaGeorge from '../resources/George.jpeg';
import pozaAndre from '../resources/Andreea.jpeg';
import Header from './Header';
import HeaderMobile from './HeaderMobile';
import Footer from './Footer';



function Person({ avatarURL, nume, descriere, linkedinLink }) {

    return (
        <Stack
            direction='column'
            sx={{
                // marginRight: '5rem'
                width: '15rem'
            }}
        >
            <Avatar
                sx={{
                    height: '12.5rem',
                    width: '12.5rem',
                    marginRight: 'auto',
                    marginLeft: 'auto',
                    marginBottom: '5rem'
                }}
                alt='profilePic'
                src={avatarURL}
            />
            <Link
                href={linkedinLink}
                target="_blank"
                underline='none'
                color='#6ca4a4'
                align='center'
                variant='h6'
            >
                {nume}
            </Link>
            <Typography
                align='center'
                sx={{
                    marginY: '3rem',
                    color: '#444444',
                }}
            >
                {descriere}
            </Typography>
            <Link
                href={linkedinLink}
                target="_blank"
                underline='none'
                color='#6ca4a4'
                align='center'
                sx={{
                    marginTop: 'auto'
                }}
            >
                LinkedIn
            </Link>
        </Stack>
    )
}


export default function MeetTeam({ matches, value, setValue }) {

    const descriere1 = "A seasoned architect and the co - founder of Crosschain Labs, Andreea has increased her blockchain experience by working with ConsenSys, NEAR Protocols and Filecoin among others.Now, she is taking the management responsibility to increase the CrossChain Labs contribution to the crypto world.";

    const descriere2 = "George Robert - Flexible and open-minded, George is the co-founder of CrossChain Labs and an experienced fullstack developer with a tremendous history of coding. Previously at ConsenSys, George continuously follows his passions for new technologies and programming.";

    const descriere3 = "Has been programming for as long as he can remember and is passionate about anything related to technology. He prefers building blockchain-related apps and solving practical problems, as hard as they might be.";

    const descriere4 = "With a background in mathematics and cybernetics, Cristina has participated to multiple hackathons and trainings during the years. Currently her main focus is on developing blockchain-related products.";

    const link1 = "https://www.linkedin.com/in/andreea-stefan-66740b20/"
    const link2 = "https://www.linkedin.com/in/george-robert-stefan-13385a9a/"
    const link3 = "https://www.linkedin.com/in/catalin-vlad-48b828229/"
    const link4 = "https://www.linkedin.com/in/cristina-varteniuc-6b3121224/"

    return (
        <>
            {matches && <Header value={value} setValue={setValue} />}
            {!matches && <HeaderMobile value={value} setValue={setValue} />}
            <Stack sx={{ width: "100%", justifyContent: 'center', display: 'flex' }} direction="column">
                <Typography
                    variant='h3'
                    sx={{
                        marginTop: '5rem',
                        marginBottom: '3rem',
                        color: '#6ca4a4',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                >
                    MEET THE TEAM
                </Typography>
                <Typography
                    variant='h6'
                    align='center'
                    sx={{
                        marginBottom: '7rem',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                >
                    We’re CrossChain Labs, a team of software developers with hands-on experience<br />
                    on blockchain services and cryptocurrency development.
                </Typography>
                <Stack
                    direction={matches ? 'row' : 'column'}
                    sx={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginBottom: '10rem'
                    }}
                    spacing={{
                        xl: 38,
                        lg: 17,
                        md: 15,
                        sm: 10,
                        xs: 5
                    }}
                >
                    <Person
                        avatarURL={pozaAndre}
                        nume='ANDREEA STEFAN'
                        descriere={descriere1}
                        linkedinLink={link1}
                    />
                    <Person
                        avatarURL={pozaGeorge}
                        nume='GEORGE ROBERT'
                        descriere={descriere2}
                        linkedinLink={link2}
                    />
                    <Person
                        avatarURL={pozaCata}
                        nume='CATALIN VLAD'
                        descriere={descriere3}
                        linkedinLink={link3}
                    />
                    <Person
                        avatarURL={pozaCriss}
                        nume='CRISTINA VARTENIUC'
                        descriere={descriere4}
                        linkedinLink={link4}
                    />
                </Stack>
            </Stack>
            <Footer />
        </>
    )
}
