import * as React from 'react';
import {
    AppBar,
    Box,
    Toolbar,
    Typography,
} from '@mui/material';

import StyledTab from './styledComponents/StyledTab';
import StyledTabs from './styledComponents/StyledTabs';

import { Link, useNavigate } from 'react-router-dom';
import logo from "../resources/logo/CrossChainLabsLogo.svg";






export default function Header({ value, setValue }) {
    const navigate = useNavigate();



    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            // behavior: 'smooth'
        });
    };

    const goHomePage = () => {
        setValue(0);
        navigate('/');
        scrollToTop();
    }



    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar
                position="static"
                sx={{
                    bgcolor: '#ffffff',
                    height: '7rem',
                    boxShadow: 3
                }}
            >
                <Toolbar
                    sx={{
                        marginTop: 'auto',
                        marginBottom: 'auto'
                    }}
                >
                    <img
                        alt="logo"
                        src={logo}
                        style={{
                            width: '3rem',
                            marginLeft: '3rem',
                            marginRight: '1.5rem',
                            cursor: 'pointer',
                        }}
                        onClick={goHomePage}
                    />
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{
                            flexGrow: 1,
                        }}
                        color="#000000"
                    >
                        CrossChain Labs
                    </Typography>
                    <StyledTabs value={value} onChange={handleChange}>
                        <StyledTab
                            label="About us"
                            component={Link} to="/"
                            onClick={scrollToTop}
                        />
                        <StyledTab
                            label="Meet the team"
                            component={Link} to="/meet-the-team"
                            onClick={scrollToTop}
                        />
                        <StyledTab
                            label="Get in touch"
                            component={Link} to="/get-in-touch"
                            onClick={scrollToTop}
                        />
                    </StyledTabs>
                </Toolbar>
            </AppBar>
        </Box>
    );
}